import styled, { css } from 'styled-components'

import { layoutBreakpointSmall } from './Layout'

const handleHeadingLevel = level => {
  switch (level) {
    case 0:
      return css`
        font-size: 4.5rem;
        line-height: 5.5rem;

        @media (max-width: ${layoutBreakpointSmall}) {
          font-size: 3.5rem;
          line-height: 4rem;
        }
      `
    case 1:
      return css`
        font-size: 4.5rem;
        line-height: 5.5rem;

        @media (max-width: ${layoutBreakpointSmall}) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      `
    case 1.5: // TODO: Refactor
      return css`
        font-size: 3rem;
        line-height: 4rem;
      `
    case 2:
      return css`
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 500;
      `
    case 3:
      return css`
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
      `
    case 4:
      return css`
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 400;
      `
    default:
      return null
  }
}

const Heading = styled.span`
  ${({ level }) => handleHeadingLevel(level)};
  color: ${props => (props.secondary ? '#424750' : '#0E131A')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
`

export default Heading
