import styled, { css } from 'styled-components'

const layoutGutter = '1.5rem'
const layoutMargin = '3rem'
const layoutMaxWidth = '90rem'
export const layoutBreakpointSmall = '37.5rem'

export const Container = styled.div``

export const Row = styled.div`
  display: grid;
  grid-column-gap: ${layoutGutter};
  width: 100%;
  max-width: ${layoutMaxWidth};

  ${props =>
    props.columns
      ? css`
          grid-template-columns: repeat(${props.columns}, 1fr);
          padding: 0 !important; /* prevents media query from overwriting */
        `
      : css`
          grid-template-columns: repeat(12, 1fr);
          padding: 0 ${layoutMargin};
        `}

  ${props =>
    props.rowGap &&
    css`
      grid-row-gap: ${props.rowGap}rem;
    `}

  margin: ${props =>
    props.verticalSpacing
      ? `${props.verticalSpacing[0]}rem auto ${props.verticalSpacing[1]}rem`
      : '0 auto'};

  @media (max-width: ${layoutBreakpointSmall}) {
    padding: 0 1.5rem;

    /* Divide top margin my 2 for mobile */
    margin: ${props =>
      props.verticalSpacing
        ? `${props.verticalSpacing[0] / 2}rem auto ${
            props.verticalSpacing[1]
          }rem`
        : '0 auto'};
  }
`

export const Column = styled.div`
  ${props =>
    props.span &&
    css`
      grid-column: ${props.offset && `${props.offset} /`} span ${props.span};
    `}

  @media (max-width: ${layoutBreakpointSmall}) {
    grid-column: span 12;
  }
`

export const Stack = styled.div`
  --space: ${props => props.space || '0'}rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  * {
    margin-top: 0;
    margin-bottom: 0;
  }
  ${props =>
    props.splitAfter &&
    `
      :only-child {
        height: 100%;
      }
      > :nth-child(${props.splitAfter}) {
        margin-bottom: auto;
      }
    `}

  ${props =>
    props.recursive
      ? `
        > * {
          margin-top: 0;
          margin-bottom: 0;
        }
        > * + * {
          margin-top: var(--space);
        }
      `
      : `
        * {
          margin-top: 0;
          margin-bottom: 0;
        }
        > * + * {
          margin-top: var(--space);
        }
    `}
`
