import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'

import { Container, Row, Column, Stack } from './Layout'
import Heading from './Heading'
import Navigation from '../components/Navigation'

import dotpatternimg from '../images/dot-pattern.svg'

const Header = ({
  title,
  intro,
  showAvatar,
  secondary,
  link,
  breadcrumbItems,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "profile.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  `)

  return (
    <Container
      className={classNames(
        secondary ? 'bg-white' : 'bg-light-blue',
        'relative z-10'
      )}
    >
      <Row>
        <Column span={12}>
          <Navigation />
        </Column>
      </Row>
      <Row>
        <Column span={10}>
          <div className="space-y-4 pt-32 pb-24">
            <h1 className="text-h1">{title}</h1>
            <h2 className="text-h2">{intro}</h2>
            {link}
          </div>
        </Column>
        <Column span={12}>
          <div className="">{children}</div>
        </Column>
        {showAvatar && (
          <Column offset={9} span={3}>
            <Img fluid={data.file.childImageSharp.fluid} alt="Profile" />
          </Column>
        )}
      </Row>
      <img
        src={dotpatternimg}
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '-1',
        }}
      />
    </Container>
  )
}

export default Header
