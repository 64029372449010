import React from 'react'
import styled from 'styled-components'

import { Container, Row, Column, Stack } from '../components/Layout'
import Link from '../components/Link'
import Text from '../components/Text'

const BreadcrumbItem = styled(Link)`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => (props.secondary ? '#424750' : '#0E131A')};
  display: inline-block;
`

const Navigation = () => (
  <nav className="flex justify-between pt-12 pb-12">
    <ul>
      <li>
        <a href="/" className="text-h4">
          Hannah Baradic
        </a>
      </li>
    </ul>
    {/* <ul className="flex justify-between space-x-12">
      <li>
        <a href="/" className="text-h4">
          Work
        </a>
      </li>
      <li>
        <a href="/" className="text-h4">
          About Me
        </a>
      </li>
      <li>
        <a href="/" className="text-h4">
          Contact
        </a>
      </li>
    </ul> */}
  </nav>
)

export default Navigation
