import styled from 'styled-components'

const Link = styled.a`
  color: #2d74ff;
  font-size: 1.25rem;
  line-height: 2rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    /* border-bottom: 1px solid #2d74ff; */
    text-decoration: underline;
  }
`

export default Link
